import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';

import {
    Box,
    Heading,
    useToast,
    Button,
    FormControl,
    FormLabel,
    Input,
    useColorModeValue,
    Center,
    VStack,
    Text,
    Stack,
    SimpleGrid,
    Container,
} from "@chakra-ui/react";

import {Field} from '../../types/fieldInterface';

import MapSelector2 from '../../components/MapSelector2';
import Navbar from '../../components/Navbar';
import useAxios from "../../utils/useAxios";


const AddModifyField = () => {
    const api = useAxios();
    const toast = useToast();
    const navigate = useNavigate();
    const { id } = useParams<{ id: string }>();
    const [isLoading, setIsLoading] = useState(true);

    const [field, setField] = useState<Field | null>(null);
    const [fieldName, setFieldName] = useState<string>('');
    const [fieldArea, setFieldArea] = useState<string>('');

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await api.get(`agtech/fields/${id}/`);
                setField(response.data);
                console.log(response.data);
                setFieldName(response.data.field_name);
                setFieldArea(response.data.area);
            } catch (error: any) {
                setField(null);
                // Toast
                toast({
                    title: "Error fetching data",
                    status: "error",
                    description: error.response.data,
                    duration: 9000,
                    isClosable: true,
                });
            }
        };
        if (id) {
            fetchData();
        } else {
            setIsLoading(false);
        }
    }, [id]);

    useEffect(() => {
        if (id) {
            if (fieldArea) {
                setIsLoading(false);
            }
        }
    }, [fieldArea]);

    const handleSave = async () => {
        try {
            if (field) {
                await api.put(`agtech/fields/${id}/`, {
                    field_name: fieldName,
                    area: fieldArea,
                });
            } else {
                await api.post(`agtech/fields/`, {
                    field_name: fieldName,
                    area: fieldArea,
                });
            }
            navigate('/fields');
        } catch (error: any) {
            console.log(error)
            // Toast
            toast({
                title: "Error saving data",
                description: error.response.data,
                status: "error",
                duration: 9000,
                isClosable: true,
            });
        }
    };

    return (
        <Navbar>
            <Container bg={useColorModeValue('white', 'gray.900')} p={4} borderRadius={8} boxShadow="md" mt={4} maxW="2xl">
            <Box>
                <Heading as="h1" size="2xl" textAlign="center" mb={4}>
                    {field ? 'Modify' : 'Add'} Field
                </Heading>
                    <VStack spacing={4} align="stretch">
                        <FormControl id="field-name" isRequired>
                            <FormLabel>Field Name</FormLabel>
                            <Input
                                type="text"
                                value={fieldName}
                                onChange={(e) => setFieldName(e.target.value)}
                                placeholder='e.g. "My Field"'
                            />
                        </FormControl>
                            <FormLabel>Field Area</FormLabel>
                            <Text>Click on the map to draw the field area</Text>
                            { ! isLoading ?
                                <MapSelector2 features={fieldArea} setFeatures={setFieldArea} />
                                : <Center><Text>Loading...</Text></Center>
                            }
                        <Button colorScheme="teal" onClick={handleSave}>
                            Save
                        </Button>
                    </VStack>
            </Box>
            </Container>
        </Navbar>
    );
}


export default AddModifyField;