import * as React from "react"
import {
  ChakraProvider,
  theme,
} from "@chakra-ui/react"
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";

import { AuthProvider } from "./context/AuthContext";
import Dashboard from "./views/Legacy/Dashboard";
import AutoPlantInfo from "./views/Legacy/AutoPlantInfo";
import Login from "./views/Authentication/Login";
import SignUp from "./views/Authentication/SignUp";
import PasswordResetConfirm from "./views/Authentication/PasswordResetConfirm";
import RequestPasswordResetView from "./views/Authentication/RequestPasswordResetView";
import NotImplemented from "./components/NotImplemented";
import TemaQuiz from "./views/TemaQuiz";
import DraginoSelect from "./views/DraginoSelect";
import DraginoViewer from "./views/DraginoViewer";
import DeviceOverview from "./views/DeviceOverview";
import DeviceViewer from "./views/DeviceViewer";
import DeviceMapView from "./views/DeviceMapView";
import AddDeviceView from "./views/AddDeviceView";
import EditDeviceView from "./views/EditDeviceView";
import SettingsView from "./views/SettingsView";
import AddTrainingDataView from "./views/internal/AddTrainingDataView";
import GatewaysView from "./views/internal/GatewaysView";
import GatewayDevicesView from "./views/internal/GatewayDevicesView";
import AutoplantView from "./views/internal/AutoplantView";
import PrivateRoute from "./utils/PrivateRoute";
import MapSelector from "./views/MapSelector";
import AddModifyField from "./views/Fields/AddModifyField";
import Fields from "./views/Fields/Fields";
import FieldView from "./views/Fields/FieldView";
import AlarmsView from "./views/AlarmsView";
import AddEditAlarm from "./views/AddEditAlarmView";
import IrrigationOverview from "./views/Irrigation/IrrigationOverview";
import IrrigationController from "./views/Irrigation/IrrigationController";

export const App = () => (
  <ChakraProvider theme={theme}>

    <Router>
      <AuthProvider>
        <Routes>
          <Route element={<PrivateRoute />}>
            <Route path="/" element={<DeviceOverview />} />
            <Route path="/map" element={<DeviceMapView />} />
            <Route path="/autoplant" element={<Dashboard />} />
            <Route path="/autoplant" element={<AutoPlantInfo />} />
            <Route path="/profile" element={<NotImplemented />} />
            <Route path="/explore" element={<NotImplemented />} />
            <Route path="/favourites" element={<NotImplemented />} />
            <Route path="/dragino/:id" element={<DraginoViewer />} />
            <Route path="/dragino" element={<DraginoSelect />} />
            <Route path="/devices/:id/edit/" element={<EditDeviceView />} />
            <Route path="/devices/:id" element={<DeviceViewer />} />
            <Route path="/add-device" element={<AddDeviceView />} />
            <Route path="/settings" element={<SettingsView />} />
            <Route path="/internal/add-training-data/" element={<AddTrainingDataView />} />
            <Route path="/internal/gateways/" element={<GatewaysView />} />
            <Route path="/internal/gateway-devices/:id" element={<GatewayDevicesView />} />
            <Route path="/internal/autoplants/" element={<AutoplantView />} />
            <Route path="/settings" element={<NotImplemented />} />
            <Route path="/map-selector" element={<MapSelector />} />
            <Route path="/fields/add" element={<AddModifyField />} />
            <Route path="/fields/edit/:id" element={<AddModifyField />} />
            <Route path="/fields" element={<Fields />} />
            <Route path="/fields/view/:id" element={<FieldView />} />
            <Route path="/alarms" element={<AlarmsView />} />
            <Route path="/alarms/add" element={<AddEditAlarm />} />
            <Route path="/alarms/edit/:id" element={<AddEditAlarm />} />
            <Route path="/irrigation" element={<IrrigationOverview />} />
            <Route path="/irrigation/:id" element={<IrrigationController />} />

          </Route>
          <Route path="*" element={<h1>404</h1>} />
          <Route path="/login" element={<Login />} />
          <Route path="/signup" element={<SignUp />} />
          <Route path="/password-reset-confirm/:uid/:token" element={<PasswordResetConfirm />} />
          <Route path="/request-password-reset" element={<RequestPasswordResetView />} />
          <Route path="/tema-quiz" element={<TemaQuiz />} />
        </Routes>
      </AuthProvider>
    </Router>
  </ChakraProvider>
)
