import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';

import {
    Box,
    Heading,
    useToast,
    Button,
    FormControl,
    FormLabel,
    Input,
    useColorModeValue,
    Center,
    VStack,
    Text,
    Stack,
    SimpleGrid,
    Container,
} from "@chakra-ui/react";

import FieldMap from '../../components/map/FieldMap';
import FieldWeatherWidget from '../../components/FieldComponents.tsx/FieldWeatherWidget';
import NotesWidget from '../../components/FieldComponents.tsx/NotesWidget';
import MoistureWidget from '../../components/FieldComponents.tsx/MoistureWidget';

import {Field} from '../../types/fieldInterface';

import Navbar from '../../components/Navbar';
import useAxios from "../../utils/useAxios";

function formatSize(sizeInMetres: number) {
    const sizeInDecares = sizeInMetres / 1000; // 1 decare is 1000 square metres
    const sizeInHectares = sizeInMetres / 10000; // 1 hectare is 10000 square metres

    if (sizeInHectares >= 1) {
        return `${sizeInHectares.toFixed(1)} hectares`;
    } else if (sizeInDecares >= 1) {
        return `${sizeInDecares.toFixed(1)} decares`;
    } else {
        return `${sizeInMetres.toFixed(1)} m^2`;
    }
}

const FieldView = () => {
    const api = useAxios();
    const toast = useToast();
    const navigate = useNavigate();
    const { id } = useParams<{ id: string }>();
    const [isLoading, setIsLoading] = useState(true);

    const [field, setField] = useState<Field | null>(null);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await api.get(`agtech/fields/${id}/`);
                setField(response.data);
                setIsLoading(false);
            } catch (error: any) {
                if (error.response.status === 401) {
                    // Currently, this is handled elsewhere
                    return;
                }
                // Toast
                toast({
                    title: 'Error fetching data',
                    status: 'error',
                    duration: 9000,
                    isClosable: true,
                });
            }
        };
        fetchData();
    }, [id]);

    return (
        <Navbar>
            <Container p={4} bg={useColorModeValue('white', 'gray.900')} boxShadow="lg" borderRadius="md" mt="4">
                <Heading as="h1" size="xl" textAlign="center" mb={4}>
                    {field?.field_name}
                </Heading>
                    <Box>
                        <Heading as="h2" size="md" my={2}>
                            General
                        </Heading>
                        <VStack align="start" spacing={2}>
                            <Text>Latitude: {field?.field_center_lat}</Text>
                            <Text>Longitude: {field?.field_center_lon}</Text>
                            <Text>Size: { field?.field_size && formatSize(field.field_size)}</Text>
                        </VStack>
                    </Box>
            </Container>
            <Container p={4} bg={useColorModeValue('white', 'gray.900')} boxShadow="lg" borderRadius="md" mt="4" maxW={"3xl"}>
                <FieldMap field_id={Number(id)} />
            </Container>
            <Container p={4} bg={useColorModeValue('white', 'gray.900')} boxShadow="lg" borderRadius="md" mt="4" maxW={"3xl"}>
                <MoistureWidget field={field as Field} />
            </Container>
            <Container p={4} bg={useColorModeValue('white', 'gray.900')} boxShadow="lg" borderRadius="md" mt="4" maxW={"3xl"}>
                <FieldWeatherWidget field={field as Field} />
            </Container>
            <Container p={4} bg={useColorModeValue('white', 'gray.900')} boxShadow="lg" borderRadius="md" mt="4" maxW={"3xl"}>
                <NotesWidget field={field as Field} />
            </Container>
        </Navbar>
    );
}

export default FieldView;