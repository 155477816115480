import {
  Flex,
  Box,
  FormControl,
  FormLabel,
  Input,
  Checkbox,
  Stack,
  Link,
  Button,
  Heading,
  Text,
  useColorModeValue,
} from '@chakra-ui/react';

import { useContext, useState, useEffect } from "react";
import { useLocation } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';

import AuthContext from "../../context/AuthContext";

export default function SimpleCard() {
  const { loginUser } = useContext(AuthContext);
  const [response, setResponse] = useState("");
  const handleSubmit = async (e: any) => {
    e.preventDefault();
    const email = e.target.email.value;
    const password = e.target.password.value;
    if (email.length > 0) {
      const response = await loginUser(email, password);
      if (response.status !== 200) {
        setResponse(JSON.stringify(response, null, 2));
      }
    }
  };
  const history = useNavigate();
  const params = new URLSearchParams(useLocation().search);

  // Set demo token if it exists
  useEffect(() => {
    const token = params.get("demo_token");
    if (token !== null) {
      localStorage.setItem("token", token);
      // check if token is stored before redirect
      const storedToken = localStorage.getItem('token');
      if (storedToken) history("/");
    }
  }, [])

  return (
    <Flex
      minH={'100vh'}
      align={'center'}
      justify={'center'}
      bg={useColorModeValue('gray.50', 'gray.900')}>
      <Stack spacing={8} mx={'auto'} maxW={'lg'} py={12} px={6}>
        <Stack align={'center'}>
          <Heading fontSize={'4xl'}>Sign in to your account</Heading>
          <Text fontSize={'lg'} color={'gray.600'}>
            Go to our <Link color={'gray.400'} href='https://botanist.no'>landing page </Link> to read more about our services.
          </Text>
        </Stack>
        <Box
          rounded={'lg'}
          as={'form'}
          bg={useColorModeValue('white', 'gray.800')}
          boxShadow={'lg'}
          p={8}
          onSubmit={handleSubmit}>
          <Stack spacing={4}>
            <FormControl label="email" id="email">
              <FormLabel>Email address</FormLabel>
              <Input type="email" autoComplete="email" />
            </FormControl>
            <FormControl label="password" id="password">
              <FormLabel>Password</FormLabel>
              <Input type="password" autoComplete="current-password" />
            </FormControl>
            <Stack spacing={10}>
              <Stack
                direction={{ base: 'column', sm: 'row' }}
                align={'start'}
                justify={'space-between'}>
                <Link href='/request-password-reset' color={'green.400'}>Forgot password?</Link>
              </Stack>
              <Button
                bg={'green.600'}
                color={'white'}
                _hover={{
                  bg: 'green.700',
                }}
                type="submit"
              >
                Sign in
              </Button>
              {response && (
                <Text color={'red.400'}>{response}</Text>
              )}
            </Stack>
            <Stack pt={6}>
              <Text align={'center'}>
                Don't have a user? <Link href="signup" color={'green.400'}>Create an account</Link>
              </Text>
            </Stack>
          </Stack>
        </Box>
      </Stack>
    </Flex>
  );
}