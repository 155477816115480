import Navbar from '../components/Navbar';
import useAxios from "../utils/useAxios";
import { useState, useEffect } from 'react';
import { useToast } from "@chakra-ui/react";
import {
    Container,
    Box,
    VStack,
    StackDivider,
    useColorModeValue,
    Heading,
    Skeleton,
    Link,
    Text,
    SimpleGrid,
    Button,
} from "@chakra-ui/react";
import DeviceTile from '../components/DeviceTile';
import { ChevronRightIcon } from "@chakra-ui/icons";

interface IData {
    id?: number;
    device_address?: string;
    version?: string;
    battery?: number;
    signal_strength?: number;
    mod?: number;
    interrupt?: number;
    soil_moisture?: number;
    soil_temperature?: number;
    soil_conductivity?: number;
    soil_dielectric_constant?: number;
    timestamp: string;
    created_at?: string;
    device?: number;
    temperature?: number;
    humidity?: number;
    lux?: number;
    battery_voltage?: number;
}

interface IDevice {
    device_id: number;
    device_name: string;
    device_description: string;
    device_type: number;
    data: IData;
    latitude?: number;
    longitude?: number;
    altitude?: number;
    accuracy?: number;
    altitudeAccuracy?: number;
}

type IDevices = IDevice[];

const DeviceOverview = () => {
    const api = useAxios();
    const toast = useToast();

    const [isLoading, setIsLoading] = useState(true);
    const [devices, setDevices] = useState<IDevices>([]);
    const box_color = useColorModeValue('white', 'gray.900');
    const link_color = useColorModeValue('teal.600', 'teal.200');

    const textColor = useColorModeValue('gray.800', 'gray.200');

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await api.get("frontend/devices/current-data/");
                console.log(response.data);
                setDevices(response.data);

                setIsLoading(false);
            } catch (error: any) {
                if (error.response.status === 401) {
                    // Currently, this is handled elsewhere
                    return;
                }
                setDevices([]);
                setIsLoading(false);
                // Toast
                toast({
                    title: "Error fetching data",
                    status: "error",
                    duration: 9000,
                    isClosable: true,
                });
            }
        };
        fetchData();
    }, []);

    return (
        <Navbar>
            <Box mx={{ base: 2, sm: 4, md: 6 }} p={5} >
                <VStack
                    mt={2}
                    spacing={4}
                    align="stretch"
                >
                    <SimpleGrid minChildWidth={{ base: '40' }} spacing="5">
                        <Heading fontSize={{ base: '2xl', sm: '3xl' }}>
                            Device overview
                        </Heading>
                        <Link href="/add-device" color={link_color} fontSize={{ base: 'l', sm: 'xl' }} fontWeight={"400"}>
                            <Button size="lg" colorScheme="teal" variant="outline" rightIcon={<ChevronRightIcon />} bgColor={box_color}>
                                <Text>
                                    Add new device
                                </Text>
                            </Button>
                        </Link>
                    </SimpleGrid>
                        {isLoading ? (
                            <Skeleton height="20px" />
                        ) : (
                            <SimpleGrid minChildWidth='250px'>
                                {devices.map((device) => (
                                    <Box borderRadius="lg" p={4} m={2} boxShadow="lg" bg={box_color} key={device.device_id} color={textColor}>
                                    <Link href={`/devices/${device.device_id}`}>
                                        <DeviceTile device={device} />
                                    </Link>
                                    </Box>
                                ))}
                            </SimpleGrid>
                        )}
                </VStack>
            </Box>
        </Navbar>
    );
}

export type { IDevice, IDevices };
export default DeviceOverview;