import {useCallback, useRef} from 'react';
import Map from 'react-map-gl';
import { Box } from "@chakra-ui/react";


import DrawControl from './map/draw-control';
import ControlPanel from './map/control-panel';

const MapSelector2 = ({features, setFeatures}) => {

  const mapDraw = useRef(null);

  const onLoadDraw = (drawInstance) => {
    if (drawInstance) {
      Object.values(features).forEach(feature => {
        drawInstance.add(feature);
      });
    }
  };

  let initialViewState = {
    longitude: 6.00,
    latitude: 59.00,
    zoom: 6
  };
  if (Object.keys(features).length > 0) {
    const firstFeature = features[Object.keys(features)[0]];
    console.log("Initial view state", firstFeature.geometry.coordinates[0][0][0], firstFeature.geometry.coordinates[0][0][1])
    if (firstFeature.geometry.type === 'Polygon') {
      initialViewState ={
        longitude: firstFeature.geometry.coordinates[0][0][0],
        latitude: firstFeature.geometry.coordinates[0][0][1],
        zoom: 12
      };
    }
  }
  else {
    console.log("No features")
  }


  const onUpdate = useCallback(e => {
    setFeatures(currFeatures => {
      const newFeatures = {...currFeatures};
      for (const f of e.features) {
        newFeatures[f.id] = f;
      }
      return newFeatures;
    });
  }, []);

  const onDelete = useCallback(e => {
    setFeatures(currFeatures => {
      const newFeatures = {...currFeatures};
      for (const f of e.features) {
        delete newFeatures[f.id];
      }
      return newFeatures;
    });
  }, []);

  return (
      <Box width="100%" height={{ base: 400, md: 400, lg: 600 }}>
        <Map
          initialViewState={initialViewState}
          mapStyle="mapbox://styles/mapbox/satellite-v9"
          mapboxAccessToken={process.env.REACT_APP_MAPBOX_TOKEN}
          style={{ width: "100%", height: "100%" }}
          mapLib={import('mapbox-gl')}
        >
          <DrawControl
            position="top-left"
            displayControlsDefault={true}
            controls={{
              polygon: true,
              line_string: false,
              point: false,
              trash: true
            }}
            defaultMode="draw_polygon"

            onLoad={onLoadDraw}
            onCreate={onUpdate}
            onUpdate={onUpdate}
            onDelete={onDelete}
          />
        </Map>
        <ControlPanel polygons={Object.values(features)} />
      </Box>
  );
}

export default MapSelector2;