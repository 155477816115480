import { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import {
    Box,
    Heading,
    useToast,
    Button,
    FormControl,
    FormLabel,
    Input,
    useColorModeValue,
    Center,
    VStack,
    Text,
    Stack,
    SimpleGrid,
    Spacer,
    Flex,
    Container,
    HStack,
} from "@chakra-ui/react";

import {
    CheckCircleIcon, WarningTwoIcon,
} from '@chakra-ui/icons';

import Navbar from '../../components/Navbar';
import useAxios from "../../utils/useAxios";

interface Data {
    temperature: number;
    humidity: number;
    lux: number;
    battery_voltage: number;
    timestamp: string;
    soil_moisture?: number;
    soil_conductivity?: number;
}

interface Device {
    device_id: number;
    device_name: string;
    device_description: string;
    device_type: number;
    latitude: number | null;
    longitude: number | null;
    accuracy: number | null;
    sensor_depth: number | null;
    data?: Data;
}

interface IrrigationOverview {
    id: number;
    user_device_controller: number;
    user_device_sensor: number | null;
    mode: number;
    start_time: string | null;
    end_time: string | null;
    time_zone: string;
    period: number | null;
    duty_cycle: number | null;
    setpoint: number | null;
    controller_data: Device;
    sensor_data?: Device;
}

const formatTimestamp = (timestamp: string): string => {
    return new Date(timestamp).toLocaleString('default', {
        year: 'numeric',
        month: 'numeric',
        day: 'numeric',
        hour: '2-digit',
        minute: '2-digit'
    });
};

const formatTimeOnly = (time: string): string => {
    console.log('Raw time:', time);
    const currentDate = new Date();
    const [hours, minutes, seconds] = time.split(':').map(Number);
    currentDate.setHours(hours, minutes, seconds || 0);
    if (isNaN(currentDate.getTime())) {
        console.error('Invalid Time:', time);
        return 'Invalid Time';
    }
    return currentDate.toLocaleTimeString('default', {
        hour: '2-digit',
    });
};

function formatPeriod(seconds: number): string {
    const hours = Math.floor(seconds / 3600);
    const minutes = Math.floor((seconds % 3600) / 60);
    const remainingSeconds = seconds % 60;

    if (hours > 0) {
        var ret_string = `${hours} hr`;	
        if (minutes > 0) {
            ret_string += ` ${minutes} min`;
        }
        return ret_string;
    } else {
        var ret_string = `${minutes} min`;
        if (remainingSeconds > 0) {
            ret_string += ` ${remainingSeconds.toFixed(0)} sec`;
        }
        return ret_string;
    }
}

const controllerModeLookup: { [key: number]: string } = {
    0: 'Manual',
    1: 'Schedule',
    2: 'Auto'
};


const IrrigationControllerTile = ({ irrigation }: { irrigation: IrrigationOverview }) => {

    const labelTextColor = useColorModeValue('gray.700', 'gray.300');

    const boxColor = useColorModeValue('white', 'gray.900');

    const api = useAxios();

    const toast = useToast();

    const handleValveOn = () => {
        api.post(`/agtech/irrigation/valve/${irrigation.id}/`, { state: true })
            .then((response) => {
                console.log('Valve On', response.data);
                toast({
                    title: 'Valve On',
                    description: response.data.message,
                    status: 'success',
                    duration: 9000,
                    isClosable: true,
                });
            })
            .catch((error) => {
                console.error('Error turning valve on', error);
                toast({
                    title: 'Error turning valve on',
                    description: error.message,
                    status: 'error',
                    duration: 9000,
                    isClosable: true,
                });
            });
    }

    const handleValveOff = () => {
        api.post(`/agtech/irrigation/valve/${irrigation.id}/`,
            { 

                state: false
            })
            .then((response) => {
                console.log('Valve Off', response.data);
                toast({
                    title: 'Valve Off',
                    description: response.data.message,
                    status: 'success',
                    duration: 9000,
                    isClosable: true,
                });
            })
            .catch((error) => {
                console.error('Error turning valve off', error);
                toast({
                    title: 'Error turning valve off',
                    description: error.message,
                    status: 'error',
                    duration: 9000,
                    isClosable: true,
                });
            });
    }


    return (
    <Box key={irrigation.id} pt={4} shadow="md" borderRadius="lg" bg={boxColor} maxWidth={400}>
    <VStack>
        <HStack>
        <Link to={`/irrigation/${irrigation.id}`} key={irrigation.id}>
            <Heading as="h2" size="lg">{irrigation.controller_data.device_name}</Heading>
        </Link>
            <Spacer />
            {irrigation.controller_data.data && irrigation.controller_data.data?.timestamp ? (
                    <>
                    <CheckCircleIcon color="green.500" />
                    <Text fontWeight={100}>Online</Text>
                    </>
                ) : (
                    <>
                    <WarningTwoIcon color="red.500" />
                    <Text>Offline</Text>
                    </>
                )}
        </HStack>
        <Flex direction="row">
            <Box p={5}>
                    <Text color={labelTextColor}>Mode:</Text>
                    <Heading size="lg">{controllerModeLookup[irrigation.mode]}</Heading>
                    {(irrigation.mode === 1 || irrigation.mode === 2) && (
                        <>
                            <Text color={labelTextColor}>Irrigation Time: </Text>
                            { !irrigation.start_time || !irrigation.end_time ? (
                                <Text>Always</Text>
                            ) : (
                                <Text fontWeight={600}>{irrigation.start_time ? formatTimeOnly(irrigation.start_time) : 'N/A'} to {irrigation.end_time ? formatTimeOnly(irrigation.end_time) : 'N/A'}</Text>
                            )}
                                <>
                                    <Text color={labelTextColor}>Interval: </Text>
                                    <Text fontWeight={600}>{ irrigation.period && formatPeriod(irrigation.period)}</Text>
                                    <Text color={labelTextColor}>Duration: </Text>
                                    <Text fontWeight={600}>{ irrigation.period && irrigation.duty_cycle && formatPeriod(irrigation.duty_cycle/2**16*irrigation.period)}</Text>
                                </>
                            </>
                    )}
                    {irrigation.mode === 2 && (
                        <>
                            <Text>Setpoint: {irrigation.setpoint} %</Text>
                        </>
                    )}
                    {irrigation.mode === 0 && (
                        <>
                        <Box mt={2}>
                            <Button onClick={handleValveOn} size="sm" mr="1">Turn On</Button>
                            <Button onClick={handleValveOff} size="sm">Turn Off</Button>
                        </Box>
                        </>
                    )}
                </Box>
            <Box p={5}>
                { irrigation.sensor_data?.data?.soil_moisture && (
                    <Link to={`/devices/${irrigation.sensor_data.device_id}`} key={irrigation.sensor_data.device_id}>
                        <Text color={labelTextColor}>Moisture:</Text>
                        <Heading size="lg">{irrigation.sensor_data?.data?.soil_moisture?.toFixed(1)} %</Heading>
                        <Text color={labelTextColor} fontWeight={100}>{irrigation.sensor_data?.data?.timestamp && formatTimestamp(irrigation.sensor_data.data.timestamp)}</Text>
                    </Link>
                )}
                </Box>
        </Flex>
    </VStack>
    </Box>
    );
}


const IrrigationOverview = () => {
    const [irrigationOverview, setIrrigationOverview] = useState<IrrigationOverview[] | null>(null);

    const api = useAxios();

    const toast = useToast();



    useEffect(() => {
        api.get('/agtech/irrigation/')
            .then((response) => {
                console.log('Irrigation Overview fetched', response.data)
                setIrrigationOverview(response.data);
            })
            .catch((error) => {
                console.error('Error fetching irrigation overview', error);
                toast({
                    title: 'Error fetching irrigation overview',
                    description: error.message,
                    status: 'error',
                    duration: 9000,
                    isClosable: true,
                });
            });
    }, []);

    return (
        <Navbar>
            <Container maxW="container.lg" mt={10}>
                <Heading as="h1" size="xl" textAlign={"center"} mb={5}>Irrigation Overview</Heading>
                <SimpleGrid minChildWidth={300} spacing={10}>
                    {irrigationOverview && irrigationOverview.map((irrigation) => (
                        <IrrigationControllerTile key={irrigation.id} irrigation={irrigation} />
                    ))}
                </SimpleGrid>
            </Container>
        </Navbar>
    );
};

export { IrrigationControllerTile, IrrigationOverview };
export default IrrigationOverview;
