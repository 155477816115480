import React, { ReactNode, useContext } from 'react';
import {
    IconButton,
    Avatar,
    Box,
    CloseButton,
    Flex,
    HStack,
    VStack,
    Icon,
    useColorModeValue,
    Link,
    Drawer,
    DrawerContent,
    Text,
    useDisclosure,
    BoxProps,
    FlexProps,
    Menu,
    MenuButton,
    MenuDivider,
    MenuItem,
    MenuList,
} from '@chakra-ui/react';
import {
    FiHome,
    FiSliders,
    FiSettings,
    FiMenu,
    FiChevronDown,
    FiGrid,
    FiDroplet,
} from 'react-icons/fi';

import {
    BiMapPin
} from 'react-icons/bi';

import {
    FaDragon
} from 'react-icons/fa';

import { RiAlarmWarningLine } from "react-icons/ri";

import { IconType } from 'react-icons';
import { ReactText } from 'react';

import { ColorModeSwitcher } from './ColorModeSwitcher';

import authContext from '../context/AuthContext';

interface LinkItemProps {
    name: string;
    href: string;
    icon: IconType;
}
const DefaultLinkItems: Array<LinkItemProps> = [
    { name: 'Fields', href: '/fields', icon: FiHome },
    { name: 'Devices', href: '/', icon: FiGrid },
    { name: 'Map', href: '/map', icon: BiMapPin },
    { name: 'Irrigation', href: '/irrigation', icon: FiDroplet },
    { name: 'Alarms', href: '/alarms', icon: RiAlarmWarningLine },
    { name: 'Settings', href: '/settings', icon: FiSettings },
];


const AdminLinkItems: Array<LinkItemProps> = [
    { name: 'Fields', href: '/fields', icon: FiHome },
    { name: 'Devices', href: '/', icon: FiGrid },
    { name: 'Map', href: '/map', icon: BiMapPin },
    { name: 'Irrigation', href: '/irrigation', icon: FiDroplet },
    { name: 'Alarms', href: '/alarms', icon: RiAlarmWarningLine },
    { name: 'Autoplant', href: '/autoplant', icon: FiHome },
    { name: 'Plant info', href: '/autoplant', icon: FiSliders },
    { name: 'Dragino', href: '/dragino', icon: FaDragon },
    { name: 'Settings', href: '/settings', icon: FiSettings },
];

const AuoPlantLinkItems: Array<LinkItemProps> = [
    { name: 'Devices', href: '/', icon: FiGrid },
    { name: 'Map', href: '/map', icon: BiMapPin },
    { name: 'Alarms', href: '/alarms', icon: RiAlarmWarningLine },
    { name: 'Autoplant', href: '/autoplant', icon: FiHome },
    { name: 'Plant info', href: '/autoplant', icon: FiSliders },
    { name: 'Settings', href: '/settings', icon: FiSettings },
];


export default function Navbar({
    children,
}: {
    children: ReactNode;
}) {
    const { isOpen, onOpen, onClose } = useDisclosure();
    return (
        <Box minH="100vh" bg={useColorModeValue('gray.100', 'gray.800')}>
            <SidebarContent
                onClose={() => onClose}
                display={{ base: 'none', md: 'block' }}
            />
            <Drawer
                autoFocus={false}
                isOpen={isOpen}
                placement="left"
                onClose={onClose}
                returnFocusOnClose={false}
                onOverlayClick={onClose}
                size="full">
                <DrawerContent>
                    <SidebarContent onClose={onClose} />
                </DrawerContent>
            </Drawer>
            {/* mobilenav */}
            <MobileNav onOpen={onOpen} />
            <Box ml={{ base: 0, md: 60 }}>
                {children}
            </Box>
        </Box>
    );
}

interface SidebarProps extends BoxProps {
    onClose: () => void;
}

const SidebarContent = ({ onClose, ...rest }: SidebarProps) => {
    const { userSettings } = useContext(authContext);
    const [LinkItems, setLinkItems] = React.useState<Array<LinkItemProps>>(DefaultLinkItems);

    React.useEffect(() => {
        if (userSettings?.user_type === 'ADMIN') {
            setLinkItems(AdminLinkItems);
        } else if (userSettings?.user_type === 'AUTOPLANT') {
            setLinkItems(AuoPlantLinkItems);
        }
    }, [userSettings]);


    return (
        <Box
            transition="3s ease"
            bg={useColorModeValue('white', 'gray.900')}
            borderRight="1px"
            borderRightColor={useColorModeValue('gray.200', 'gray.700')}
            w={{ base: 'full', md: 60 }}
            pos="fixed"
            h="full"
            {...rest}>
            <Flex h="20" alignItems="center" mx="8" justifyContent="space-between">
                <Text as="span" fontSize="2xl" fontFamily="monospace" fontWeight="bold">
                    BOTanist
                </Text>
                <CloseButton display={{ base: 'flex', md: 'none' }} onClick={onClose} />
            </Flex>
            {LinkItems.map((link) => (
                <NavItem key={link.name} icon={link.icon} href={link.href}>
                    {link.name}
                </NavItem>
            ))}
        </Box>
    );
};

interface NavItemProps extends FlexProps {
    icon: IconType;
    href: string;
    children: ReactText;
}
const NavItem = ({ icon, href, children, ...rest }: NavItemProps) => {
    return (
        <Link href={href} style={{ textDecoration: 'none' }} _focus={{ boxShadow: 'none' }}>
            <Flex
                align="center"
                p="4"
                mx="4"
                borderRadius="lg"
                role="group"
                cursor="pointer"
                _hover={{
                    bg: 'green.400',
                    color: 'white',
                }}
                {...rest}>
                {icon && (
                    <Icon
                        mr="4"
                        fontSize="16"
                        _groupHover={{
                            color: 'white',
                        }}
                        as={icon}
                    />
                )}
                {children}
            </Flex>
        </Link>
    );
};

interface MobileProps extends FlexProps {
    onOpen: () => void;
}
const MobileNav = ({ onOpen, ...rest }: MobileProps) => {

    const { user, logoutUser } = useContext(authContext);

    const [displayName, setDisplayName] = React.useState<string>('');

    React.useEffect(() => {
        if (user?.first_name && user?.last_name) {
            setDisplayName(user.first_name + " " + user.last_name);
        } else if (user?.username) {
            setDisplayName(user.username);
        } else {
            setDisplayName('');
        }
    }, [user]);

    return (
        <Flex
            ml={{ base: 0, md: 60 }}
            px={{ base: 4, md: 4 }}
            height="20"
            alignItems="center"
            bg={useColorModeValue('white', 'gray.900')}
            borderBottomWidth="1px"
            borderBottomColor={useColorModeValue('gray.200', 'gray.700')}
            justifyContent={{ base: 'space-between', md: 'flex-end' }}
            {...rest}>
            <IconButton
                display={{ base: 'flex', md: 'none' }}
                onClick={onOpen}
                variant="outline"
                aria-label="open menu"
                icon={<FiMenu />}
            />

            <Text
                display={{ base: 'flex', md: 'none' }}
                fontSize="2xl"
                fontFamily="monospace"
                fontWeight="bold">
                BOTanist
            </Text>

            <HStack spacing={{ base: '0', md: '6' }}>
                <ColorModeSwitcher justifySelf="flex-end" />
                <Flex alignItems={'center'}>
                    <Menu>
                        <MenuButton
                            py={2}
                            transition="all 0.3s"
                            _focus={{ boxShadow: 'none' }}>
                            <HStack>
                                <Avatar
                                    size={'sm'}
                                />
                                <VStack
                                    display={{ base: 'none', md: 'flex' }}
                                    alignItems="flex-start"
                                    spacing="1px"
                                    ml="2">
                                    <Text fontSize="sm">{displayName}</Text>
                                </VStack>
                                <Box display={{ base: 'none', md: 'flex' }}>
                                    <FiChevronDown />
                                </Box>
                            </HStack>
                        </MenuButton>
                        <MenuList
                            bg={useColorModeValue('white', 'gray.900')}
                        >
                            <Link href='/profile'><MenuItem>Profile</MenuItem></Link>
                            <Link href='/settings'><MenuItem>Settings</MenuItem></Link>
                            <MenuItem> </MenuItem>
                            <MenuDivider />
                            <MenuItem onClick={logoutUser}>Sign out</MenuItem>
                        </MenuList>
                    </Menu>
                </Flex>
            </HStack>
        </Flex>
    );
};