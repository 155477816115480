import { Box, Container, keyframes, Text, Center, Icon, VStack } from '@chakra-ui/react';
import { motion } from 'framer-motion';
import { FaSeedling } from "react-icons/fa";

const animationKeyframes = keyframes`
  0% { transform: scale(1) rotate(0); border-radius: 20%; }
  25% { transform: scale(2) rotate(0); border-radius: 20%; }
  50% { transform: scale(2) rotate(270deg); border-radius: 50%; }
  75% { transform: scale(1) rotate(270deg); border-radius: 50%; }
  100% { transform: scale(1) rotate(0); border-radius: 20%; }
`;

const animation = `${animationKeyframes} 2s ease-in-out infinite`;
  
export default function FancyPageLoadingAnymation() {
  return (
    <Container h="100vh" display="flex" alignItems="center" justifyContent="center">
      <Box
        as={motion.div}
        animation={animation}
        // not work: transition={{ ... }}
        padding="2"
        // @ts-ignore - "Does not exist" Type Error against Motion
        width="20"
        height="20"
        display="flex"
      >
        <Center>
            <VStack>
            <Icon boxSize="5" color="white" as={FaSeedling} />
            <Text color="white" fontSize="sm" fontWeight="bold">
                Loading...
            </Text>
            </VStack>
        </Center>
        </Box>
    </Container>
  )
};
